<template>
  <div v-if="!data || !bem">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <b-form-group
        label="Erweiterter Bedarf"
        label-for="ErwBedarf"
        label-cols-md="2"
      >
        <b-form-checkbox
          v-model="erwBedarf"
          class="custom-control-primary"
          name="check-button"
          @change="setFilter"
          switch
        />
      </b-form-group>

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :items="data"
        primary-key="BedarfID"
        :fields="fields"
        :filter="erwBedarf.toString()"
        :filter-function="filterExtend"
      >
        <template v-slot:cell(Bedarf)="row">
          <span
            v-b-tooltip.html="{ variant: 'secondary' }"
            :title="row.item.Info"
            :class="{ 'font-weight-bold bg-light': row.item.BedarfID === 9999 }"
          >{{ row.item.Bedarf }}</span>
        </template>
        <template v-slot:cell(WertK1)="row">
          <b-form-input v-facade="'#######'" :formatter="formatNumber" v-model="row.item.WertK1" class="text-right"
            :readonly="row.item.BedarfID === 9999"
            :class="{ 'font-weight-bold bg-light': row.item.BedarfID === 9999 }"
            @blur="applyFormat($event, row, 'WertK1')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertK2)="row">
          <b-form-input v-facade="'#######'" v-model="row.item.WertK2" class="text-right" :formatter="formatNumber"
            :readonly="row.item.BedarfID === 9999"
            :class="{ 'font-weight-bold bg-light': row.item.BedarfID === 9999 }"
            @blur="applyFormat($event, row, 'WertK2')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertK3)="row">
          <b-form-input v-facade="'#######'" v-model="row.item.WertK3" class="text-right" :formatter="formatNumber"
          :readonly="row.item.BedarfID === 9999"
            :class="{ 'font-weight-bold bg-light': row.item.BedarfID === 9999 }"
            @blur="applyFormat($event, row, 'WertK3')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertK4)="row">
          <b-form-input v-facade="'#######'" v-model="row.item.WertK4" class="text-right" :formatter="formatNumber"
            :readonly="row.item.BedarfID === 9999"
            :class="{ 'font-weight-bold bg-light': row.item.BedarfID === 9999 }"
            @blur="applyFormat($event, row, 'WertK4')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertK5)="row">
          <b-form-input v-facade="'#######'" v-model="row.item.WertK5" class="text-right" :formatter="formatNumber"
            :readonly="row.item.BedarfID === 9999"
            :class="{ 'font-weight-bold bg-light': row.item.BedarfID === 9999 }"
            @blur="applyFormat($event, row, 'WertK5')"
            @focus="removeFormat"
          />
        </template>
        <template v-slot:cell(WertK6)="row">
          <b-form-input v-facade="'#######'" v-model="row.item.WertK6" class="text-right" :formatter="formatNumber"
            :readonly="row.item.BedarfID === 9999"
            :class="{ 'font-weight-bold bg-light': row.item.BedarfID === 9999 }"
          @blur="applyFormat($event, row, 'WertK6')"
            @focus="removeFormat"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group
            label="Bemerkung"
            label-for="BemKinder"
          >
            <b-form-textarea
              id="BemKinder"
              v-model="bem.KindBedarfBem"
              rows="2"
              max-rows="4"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="save_changes(true)"
    >
      Speichern
    </b-button>
    <b-button
        variant="secondary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="fetchData()"
      >
        Abbrechen
      </b-button>
  </div>
</template>

<script>
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import { required } from '@validations'

import {
  BTable, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, VBTooltip, BFormCheckbox,
} from 'bootstrap-vue'
import db from '@/service/mittelbedarf'

export default {
  components: {
    BTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      erwBedarf: false,
      data: null,
      bem: null,
      isEditing: false,
      perPage: 40,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'Bedarf', label: 'Bedarf' },
        { key: 'WertK1', label: 'Kind 1', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK2', label: 'Kind 2', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK3', label: 'Kind 3', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK4', label: 'Kind 4', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK5', label: 'Kind 5', thClass: 'text-right' }, // eslint-disable-line
        { key: 'WertK6', label: 'Kind 6', thClass: 'text-right' }, // eslint-disable-line
      ],
    }
  },
  async mounted() {
    this.$root.$refs.BEDARFKIND = this
  },
  async created() {
    await this.fetchData()
    if (this.data !== null && this.data.length > 0) {
      this.totalRows = this.data.length
      this.fields[1].label = this.data[0].VornameK1
      this.fields[2].label = this.data[0].VornameK2
      this.fields[3].label = this.data[0].VornameK3
      this.fields[4].label = this.data[0].VornameK4
      this.fields[5].label = this.data[0].VornameK5
      this.fields[6].label = this.data[0].VornameK6
      if (this.data[0].VornameK6 === null) this.fields.splice(6, 1)
      if (this.data[0].VornameK5 === null) this.fields.splice(5, 1)
      if (this.data[0].VornameK4 === null) this.fields.splice(4, 1)
      if (this.data[0].VornameK3 === null) this.fields.splice(3, 1)
      if (this.data[0].VornameK2 === null) this.fields.splice(2, 1)
    }
  },
  methods: {
    async fetchData() {
      this.data = null
      const r = await db.getBedarfKind(this.$root.currDosID)
      if (r.data.length > 0) {
        this.data = r.data

        this.data.push({
          BedarfID: 9999,
          Bedarf: 'Total',
          WertK1: 0,
          WertK2: 0,
          WertK3: 0,
          WertK4: 0,
          WertK5: 0,
          WertK6: 0,
          Erweitert: this.erwBedarf,
        })

        this.sumUp()
      }
      const rb = await db.getKindBem(this.$root.currDosID)
      this.bem = rb.data[0]
    },
    sumUp() {
      let ix = 0
      let su1 = 0
      let su2 = 0
      let su3 = 0
      let su4 = 0
      let su5 = 0
      let su6 = 0
      for (; ix < this.data.length - 1; ix++) { // eslint-disable-line
        if (this.data[ix].Erweitert === this.erwBedarf) {
          su1 += Number((this.data[ix].WertK1 || '').toString().replace(/'/g, '')) || 0
          su2 += Number((this.data[ix].WertK2 || '').toString().replace(/'/g, '')) || 0
          su3 += Number((this.data[ix].WertK3 || '').toString().replace(/'/g, '')) || 0
          su4 += Number((this.data[ix].WertK4 || '').toString().replace(/'/g, '')) || 0
          su5 += Number((this.data[ix].WertK5 || '').toString().replace(/'/g, '')) || 0
          su6 += Number((this.data[ix].WertK6 || '').toString().replace(/'/g, '')) || 0
        }
      }
      this.data[this.data.length - 1].WertK1 = su1
      this.data[this.data.length - 1].WertK2 = su2
      this.data[this.data.length - 1].WertK3 = su3
      this.data[this.data.length - 1].WertK4 = su4
      this.data[this.data.length - 1].WertK5 = su5
      this.data[this.data.length - 1].WertK6 = su6
    },
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.data.length; ix++) if (this.data[ix].BedarfID === row.item.BedarfID) break // eslint-disable-line
      this.data[ix][value] = event.target.value
      this.isEditing = false
      this.sumUp()
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async save_changes(showToast) {
      await db.putBedarfKind(this.data)
      this.$eventBus.$emit('dataBedarfSaved') // send event to all components which need to reload the data...
      await db.putBemKind(this.$root.currDosID, this.bem)

      if (showToast) this.$root.showToast('Speichern', 'Bedarf-Daten Kind erfolgreich gespeichert!')
    },
    filterExtend(row, filter) { // eslint-disable-line
      return row.Erweitert === (filter === 'true')
    },
    setFilter() {
      this.data[this.data.length - 1].Erweitert = this.erwBedarf
      this.sumUp()
    },
  },
}
</script>
